import { createContext, useMemo, useEffect, useState } from "react";
import useSupabase from "./useSupabase";
import useTable from "./useTable";
import { Tables, Enums } from "../../types/database";
import { SupabaseClient, User as SupabaseUser } from "@supabase/supabase-js";
import { Team } from "./useTeams";
import { debugLog } from "../../lib/debug";

export type RoleEnum = Enums<"role_type">;
export type UserTeamBase = Tables<"user_team">;
export type UserTeam = UserTeamBase & { team?: Partial<Team> };
export type UserTeams = {
  [key: number]: UserTeam;
} | null;

export type IsLoadingSessionType =
  | "Not Ready"
  | "Loading Session"
  | "Session Ready"
  | "Loading Session Teams"
  | "Session Teams Ready";

type SessionContextType = {
  user?: SupabaseUser;
  isLoading: IsLoadingSessionType;
  sessionTeams?: {
    [key: string]: UserTeam;
  };
  updateUserTeam: (user_team: UserTeam) => Promise<UserTeam[]>;
  insertUserTeams: (user_team: UserTeam[]) => Promise<UserTeam[]>;
  deleteUserTeams: (user_team: UserTeam[]) => Promise<UserTeam[]>;
  toggleArchived: (userteam: UserTeam) => void;
  userHasRoles?: (team_id: number) => {
    admin: boolean;
    edit: boolean;
    review: boolean;
    chat: boolean;
  };
  fetchSessionTeams: () => void;
  editPanels: boolean;
  toggleEditPanels: () => void;
  signOut?: () => void;
};

export const SessionContext = createContext<SessionContextType>({
  isLoading: "Not Ready",
  user: null,
  sessionTeams: {},
  updateUserTeam: null,
  insertUserTeams: null,
  deleteUserTeams: null,
  userHasRoles: null,
  fetchSessionTeams: null,
  toggleArchived: null,
  editPanels: true,
  toggleEditPanels: null,
  signOut: null,
});

export function useSession({
  visible = true,
}: {
  visible?: boolean;
}): SessionContextType {
  const client = useSupabase();
  const [user, setUser] = useState<SupabaseUser>(null);
  const key = useMemo(() => ["user_team", user?.email], [user?.email]);
  const [isLoadingSession, setIsLoadingSession] =
    useState<IsLoadingSessionType>("Not Ready");
  const {
    rows: userTeamsArray,
    updateRow: updateUserTeam,
    insertRows: insertUserTeams,
    deleteRows: deleteUserTeams,
    refetch,
  } = useTable<UserTeam>({
    table: "user_team",
    visible,
    key,
    filter: `email=eq.${user?.email}`,
    selectFunction: async (): Promise<UserTeam[]> => {
      let response: UserTeam[] = [];
      if (user?.email) {
        if (sessionTeams === null) {
          setIsLoadingSession("Loading Session Teams");
        }
        response = await selectFunction({ client, email: user?.email });
        setIsLoadingSession("Session Teams Ready");
      } else {
      }
      return response;
    },
    updateFunction: async (user_team: UserTeam): Promise<UserTeam[]> =>
      await updateFunction({ client, user_team }),
    insertFunction: async (user_teams: UserTeam[]): Promise<UserTeam[]> =>
      await insertFunction({ client, user_teams }),
    deleteFunction: async (user_teams: UserTeam[]): Promise<UserTeam[]> =>
      await deleteFunction({ client, user_teams }),
  });
  const [editPanels, setEditPanels] = useState<boolean>(false);
  const toggleEditPanels = () => setEditPanels((prev) => !prev);

  // render the array into an object with team_id as key
  const sessionTeams: UserTeams = useMemo(
    () =>
      user?.email
        ? userTeamsArray.reduce((obj: UserTeams, item: UserTeam) => {
            return {
              ...obj,
              [item.team.id]: item,
            };
          }, {})
        : null,
    [userTeamsArray, user?.email]
  );

  useEffect(() => {
    setIsLoadingSession("Loading Session");

    client.auth.getSession().then(({ data: { session: latestSession } }) => {
      const latestUser: SupabaseUser = latestSession?.user ?? null;
      setUser(latestUser);

      // if session has a user email, fetch session teams
      if (latestUser?.email) {
        setIsLoadingSession("Loading Session Teams");
        refetch();
      } else {
        // otherwise set the state to session ready
        setIsLoadingSession("Session Ready");
      }
    });

    const { data: authListener } = client.auth.onAuthStateChange(
      (event, latestSession) => {
        const userDidChange = latestSession?.user?.id !== user?.id;
        debugLog(
          `useSession Supabase auth event: ${event} ${
            userDidChange ? "(user did change)" : "(user did not change)"
          }`
        );

        // only update on onAuthStateChange event if there is change to the user
        if (userDidChange) {
          setUser(latestSession?.user ?? null);
        }
      }
    );

    return () => authListener.subscription.unsubscribe();
  }, [user?.email, client.auth, user?.id, refetch]);

  const userHasRoles = (team_id: number) =>
    typeof team_id !== "number" ||
    (isLoadingSession !== "Session Teams Ready" &&
      isLoadingSession !== "Session Ready")
      ? {
          admin: undefined,
          edit: undefined,
          chat: undefined,
          review: undefined,
        }
      : {
          admin: (sessionTeams?.[team_id]?.role === "admin") as boolean,
          edit:
            ["admin", "edit"].includes(sessionTeams?.[team_id]?.role) ?? false,
          review:
            ["admin", "edit", "review"].includes(
              sessionTeams?.[team_id]?.role
            ) ?? false,
          chat:
            ["admin", "edit", "review", "chat"].includes(
              sessionTeams?.[team_id]?.role
            ) ?? false,
        };

  const signOut = async () => {
    await client.auth.signOut();
  };

  const toggleArchived = async (userteam: UserTeam) => {
    await client
      .from("user_team")
      .update({ archived: !userteam.archived })
      .eq("id", userteam.id)
      .throwOnError();
    refetch();
  };

  return {
    isLoading: isLoadingSession,
    user,
    sessionTeams,
    updateUserTeam,
    insertUserTeams,
    deleteUserTeams,
    userHasRoles,
    fetchSessionTeams: () => refetch(),
    signOut,
    toggleArchived,
    editPanels,
    toggleEditPanels,
  };
}

const selectFunction = async ({
  client,
  email,
}: {
  client: SupabaseClient;
  email: string;
}): Promise<UserTeam[]> =>
  email
    ? client
        .from("user_team")
        .select("id, email, archived, username, role, teams(*)")
        .eq("email", email)
        .then((result): UserTeam[] =>
          result.data.map((item): UserTeam => {
            const team = Array.isArray(item.teams) ? item.teams[0] : item.teams;
            return {
              id: item.id,
              email: item.email,
              team_id: team.id,
              archived: item.archived,
              role: item.role,
              username: item.username,
              team: team,
            };
          })
        )
    : [];

export const updateFunction = async ({
  client,
  user_team,
}: {
  client: SupabaseClient;
  user_team: UserTeam;
}): Promise<UserTeam[]> =>
  client
    .from("user_team")
    .update(user_team)
    .eq("id", user_team.id)
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): UserTeam[] => result.data);

export const insertFunction = async ({
  client,
  user_teams,
}: {
  client: SupabaseClient;
  user_teams: UserTeam[];
}): Promise<UserTeam[]> =>
  client
    .from("user_team")
    .insert(user_teams)
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): UserTeam[] => result.data);

export const deleteFunction = async ({
  client,
  user_teams,
}: {
  client: SupabaseClient;
  user_teams: UserTeam[];
}): Promise<UserTeam[]> =>
  client
    .from("user_team")
    .delete()
    .in(
      "id",
      user_teams.map((m) => m.id)
    )
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): UserTeam[] => result.data);
