import {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import useSupabase from "./useSupabase";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { RealtimeChannel } from "@supabase/realtime-js";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";

interface Row {
  id?: number | string;
}

type onInserDeletetListenerType<T> = (row: T) => void;
type onUpdateListenerType<T> = (previous: T, updated: T) => void;

export type onListenerCallbacksType<T> = {
  insert?: onInserDeletetListenerType<T>;
  delete?: onInserDeletetListenerType<T>;
  update?: onUpdateListenerType<T>;
};

export default function useTable<T extends Row>({
  table,
  events,
  visible = true,
  key = [table],
  filter,
  refetchOnPayload = false, // refetch whenever payload is received from channel listener
  idAltPredicate = null,
  selectFunction,
  updateFunction,
  upsertFunction,
  insertFunction,
  deleteFunction,
  debug = false,
  onListeners,
}: {
  table: string;
  events?: ("INSERT" | "UPDATE" | "DELETE")[];
  visible?: boolean;
  key?: (string | number)[];
  filter: string;
  refetchOnPayload?: boolean;
  idAltPredicate?: (row: T) => string;
  selectFunction: () => Promise<T[]>;
  updateFunction: (row: T) => Promise<T[]>;
  upsertFunction?: (row: T | T[]) => Promise<T[]>;
  insertFunction: (row: T | T[]) => Promise<T[]>;
  deleteFunction: (row: T | T[]) => Promise<T[]>;
  debug?: boolean;
  onListeners?: onListenerCallbacksType<T>;
}): {
  rows: T[];
  isLoading: boolean;
  isPending: boolean;
  isFetching: boolean;
  isFetched: boolean;
  error: Error | null;
  cache: (index?: number) => T[] | T | null;
  updateRow: (row: T) => Promise<T[]>;
  upsertRows: (row: T | T[]) => Promise<T[]>;
  insertRows: (row: T | T[]) => Promise<T[]>;
  deleteRows: (row: T | T[]) => Promise<T[]>;
  refetch: () => void;
  clearError: () => void;
} {
  const client = useSupabase();
  const [error, setError] = useState<Error | null>(null);
  const clearError = useCallback(() => setError(null), [setError]);
  // memoize the events array to ensure it has a stable reference
  const eventsMemoized = useMemo(
    (): ("INSERT" | "UPDATE" | "DELETE")[] =>
      events || ["INSERT", "UPDATE", "DELETE"],
    [events]
  );
  const {
    data: rows,
    isLoading,
    isPending,
    isFetching,
    isFetched,
    refetch,
  } = useQuery<T[]>({
    queryKey: key,
    queryFn: () => selectFunction(),
  });
  const queryClient = useQueryClient();
  // create a ref of rows to use in dependency arrays to avoid retriggering
  const rowsRef = useRef<T[]>(rows);
  useEffect(() => {
    rowsRef.current = rows;
  }, [rows]);
  const listener = useRef<RealtimeChannel>(null);

  // find matching rows by id or using the idAltPredicate
  const findMatchingRow = useCallback(
    (data: T[], row: T): T | undefined =>
      data.find(
        (e) =>
          (row.id && row.id === e.id) ||
          (!e.id &&
            idAltPredicate !== null &&
            idAltPredicate(e) === idAltPredicate(row))
      ),
    [idAltPredicate]
  );
  // build query-data updater callbacks
  const setQueryDataUpdate = useCallback(
    (row: T) =>
      queryClient.setQueryData(key, (data: T[]): T[] =>
        data.map((e) => {
          return findMatchingRow([e], row) ? row : e;
        })
      ),
    [queryClient, key, findMatchingRow]
  );
  const setQueryDataInsert = useCallback(
    (rows: T[]) =>
      queryClient.setQueryData(key, (data: T[]): T[] => [
        ...data.filter((e) => !rows.map((r) => r.id).includes(e.id)),
        ...rows,
      ]),
    [queryClient, key]
  );
  const setQueryDataDelete = useCallback(
    (rows: T[]) =>
      queryClient.setQueryData(key, (data: T[]): T[] =>
        data.filter((e) => !rows.map((r) => r.id).includes(e.id))
      ),
    [queryClient, key]
  );
  const setQueryDataUpsert = useCallback(
    (rows: T[]) =>
      queryClient.setQueryData(key, (data: T[]): T[] => {
        const updatedRows = data.map((e) => {
          const matchingRow = findMatchingRow(rows, e);
          return matchingRow ? matchingRow : e;
        });
        const insertedRows = rows.filter(
          (row) => !findMatchingRow(updatedRows, row)
        );
        return [...updatedRows, ...insertedRows];
      }),
    [queryClient, key, findMatchingRow]
  );

  const insertRows = useRowMutation<T>({
    key,
    databaseFunction: async (rows) =>
      insertFunction(Array.isArray(rows) ? rows : [rows]),
    setQueryData: setQueryDataInsert,
    updateQueryData: setQueryDataUpdate,
    onError: setError,
  });

  const updateRow = useRowMutation<T>({
    key,
    databaseFunction: async (row) => updateFunction(row),
    setQueryData: setQueryDataUpdate,
    onError: setError,
  });

  const upsertRows = useRowMutation<T>({
    key,
    databaseFunction: async (rows) =>
      upsertFunction(Array.isArray(rows) ? rows : [rows]),
    setQueryData: setQueryDataUpsert,
    updateQueryData: setQueryDataUpdate,
    onError: setError,
  });

  const deleteRows = useRowMutation<T>({
    key,
    databaseFunction: async (rows) =>
      deleteFunction(Array.isArray(rows) ? rows : [rows]),
    setQueryData: setQueryDataDelete,
    onError: setError,
  });

  const cache = (index?: number) => {
    const cacheRows = queryClient.getQueryData<T[]>(key);
    return index === undefined
      ? cacheRows
      : index >= 0 && index < cacheRows.length
      ? cacheRows[index]
      : null;
  };

  const logHook = useCallback(
    (message: string) => {
      if (debug) console.log(`useTable:'${table}':${message}`);
    },
    [debug, table]
  );

  const stopUseTable = useCallback(
    async (status?: string) => {
      if (listener.current) {
        if (status) {
          logHook(`stopUseTable:${status}`);
        }

        client.removeChannel(listener.current);
        listener.current = null;
      }
    },
    [client, logHook]
  );

  useEffect(() => {
    if (visible) {
      logHook("setting up listener");
      listener.current = client.channel(
        `public:${table}:${eventsMemoized.join("")}:${filter}:listener`
      );

      if (eventsMemoized.includes("UPDATE")) {
        listener.current.on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table,
            filter,
          },
          (payload: RealtimePostgresChangesPayload<T>) => {
            logHook(`payload.eventType:${payload.eventType}`);
            logHook(`payload:${JSON.stringify(payload)}`);

            // report UPDATE to listener callback if configured
            if (onListeners?.update) {
              // on UPDATE, payload.old contains only the row's id
              // find and return the complete object
              onListeners.update(
                rowsRef?.current?.find(
                  (e) => "id" in payload?.old && e?.id === payload.old?.id
                ) ?? null,
                payload.new as T
              );
            }

            if (refetchOnPayload) {
              refetch();
            } else {
              setQueryDataUpdate(payload.new as T);
            }
          }
        );
      }
      if (eventsMemoized.includes("DELETE")) {
        listener.current.on(
          "postgres_changes",
          {
            event: "DELETE",
            schema: "public",
            table,
            // don't include (team) filter as DELETE records are minimized to "old": { "id": 36611 } and "new": { }
            // filter,
          },
          (payload: RealtimePostgresChangesPayload<T>) => {
            logHook(`payload.eventType:${payload.eventType}`);
            logHook(`payload:${JSON.stringify(payload)}`);

            // report DELETE to listener callback if configured
            if (onListeners?.delete) {
              // on DELETE, payload.old contains only the row's id
              // if complete object can be found in `rowsRef`, return it
              const row = rowsRef?.current?.find(
                (e) => "id" in payload.old && e?.id === payload.old?.id
              );
              // if the row is found, call the `onListeners` handler
              if (row) {
                onListeners.delete(row);
              } else {
                logHook(`deleted row not recognized`);
              }
            }

            if (refetchOnPayload) {
              refetch();
            } else {
              setQueryDataDelete([payload.old as T]);
            }
          }
        );
      }
      if (eventsMemoized.includes("INSERT")) {
        listener.current.on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table,
            filter,
          },
          (payload: RealtimePostgresChangesPayload<T>) => {
            logHook(`payload.eventType:${payload.eventType}`);
            logHook(`payload:${JSON.stringify(payload)}`);

            // report INSERT to listener callback if configured
            if (onListeners?.insert) {
              onListeners.insert(payload.new as T);
            }

            if (refetchOnPayload) {
              refetch();
            } else {
              setQueryDataInsert([payload.new as T]);
            }
          }
        );
      }
      listener.current
        .on("system" as any, {} as any, (payload: any) => {
          logHook(`system: used to fetch all here`);
          if (payload.extension === "postgres_changes") {
            logHook(`system: used to fetch all here`);
          }
        })
        .subscribe((status) => {
          if (["SUBSCRIBED", "CLOSED"].includes(status) && visible) {
            logHook(`status: ${status}`);
          } else if (visible) {
            stopUseTable(`subscribe=${status}`);
          }
        });

      logHook("invalidating after visibility change");
      queryClient.invalidateQueries({ queryKey: key });
    } else if (listener.current) {
      stopUseTable("hiding");
    }

    return () => {
      stopUseTable("useEffect unmount");
    };
  }, [
    client,
    eventsMemoized,
    filter,
    key,
    logHook,
    queryClient,
    onListeners,
    refetch,
    refetchOnPayload,
    setQueryDataDelete,
    setQueryDataInsert,
    setQueryDataUpdate,
    stopUseTable,
    table,
    visible,
  ]);

  return {
    rows: rows ?? [],
    isLoading,
    isPending,
    isFetching,
    isFetched,
    error,
    cache,
    updateRow: updateRow.mutateAsync,
    insertRows: insertRows.mutateAsync,
    upsertRows: upsertRows.mutateAsync,
    deleteRows: deleteRows.mutateAsync,
    refetch,
    clearError,
  };
}

function useRowMutation<T extends Row>({
  setQueryData,
  updateQueryData,
  databaseFunction,
  key,
  onError,
}: {
  setQueryData: (data: T | T[]) => void;
  updateQueryData?: (data: T) => void;
  databaseFunction: (data: T) => Promise<T[]>;
  key: (string | number)[];
  onError?: Dispatch<SetStateAction<Error>>;
}) {
  const queryClient = useQueryClient();
  const memoizedDatabaseFunction = useCallback(databaseFunction, [
    databaseFunction,
  ]);

  return useMutation<T[], Error, T | T[]>({
    mutationFn: async (data: T): Promise<T[]> =>
      await memoizedDatabaseFunction(data),
    retry: 3,
    retryDelay: (attempt) =>
      Math.min(attempt > 1 ? (2 ^ attempt) * 1000 : 1000, 10000),
    onMutate: async (data: T | T[]) => {
      await queryClient.cancelQueries({ queryKey: key });
      const snapshot: T[] = queryClient.getQueryData(key);
      setQueryData(data);
      return { snapshot };
    },
    onSuccess: (rows: T[]) => {
      if (updateQueryData && rows.length > 0) {
        updateQueryData(rows[0]);
      }
    },
    onError: (error, newRow: T | T[], context: { snapshot: T[] }) => {
      queryClient.setQueryData(key, context?.snapshot);
      onError?.(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: key });
    },
  });
}
